import { t } from '../../languages';
import { defineStore } from "pinia";
import { store } from "@/store";

const useSearchStore = defineStore({
    id: "search",
    state: () => ({
        searchValue: ""
    }),
    actions: {
        setSearchValue(value) {
            this.searchValue = value
        },
        clearSearchValue() {
            this.searchValue = ""
        },
        getSearchValue() {
            return this.searchValue
        }
    }
});

export default function useSearchStoreHook() {
    return useSearchStore(store);
}