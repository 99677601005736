import { t } from '../../languages';
import { defineStore } from "pinia";
import { store } from "@/store";
import { storageLocal } from "@pureadmin/utils";
import { refreshToken, emailLogin, getUserInfo, logout } from "@/http/api";

const tokenKey = "tokenInfo";
const userKey = "userInfo";
export const useUserStore = defineStore({
    id: "user",
    state: () => ({
        tokenInfo: storageLocal().getItem(tokenKey) ?? {},
        userInfo: storageLocal().getItem(userKey) ?? {},
        isLogin: storageLocal().getItem(tokenKey) ? true : false
    }),
    actions: {
        async login(data) {
            let res = await emailLogin({
                ...data
            })
            this.setToken(res);
            this.setUserInfo(res)
            this.isLogin = true
            return res;
        },
        // 设置token
        setToken(data) {
            const { token, refreshToken, expires } = data;
            let tokenInfo = {
                token,
                refreshToken,
                expires
            }
            storageLocal().setItem(tokenKey, tokenInfo);
            this.tokenInfo = tokenInfo;
            this.isLogin = true
        },
        // 获取Toekn
        getToken() {
            return this.tokenInfo;
        },
        // 刷新token
        async handRefreshToken() {
            let res = await refreshToken({}, {
                headers: {
                    refreshToken: this.tokenInfo.refreshToken
                }
            });
            this.setToken(res);
            this.isLogin = true
            return res
        },
        // 更新用户信息
        async setUserInfo() {
            let res = await getUserInfo();
            this.userInfo = {
                id: res.id,
                nickName: res.nickName,
                avatar: res.avatar,
                email: res.email
            };
            storageLocal().setItem(userKey, this.userInfo);
        },
        // 退出登录
        async logout() {
            await logout()
            this.isLogin = false
            this.removeToken()
            this.removeUserInfo()
        },
        removeToken() {
            this.tokenInfo = null
            storageLocal().removeItem(tokenKey);
        },
        removeUserInfo() {
            this.userInfo = null
            storageLocal().removeItem(userKey);
        },
        cloaseLoginInfo() {
            this.isLogin = false
            this.removeToken()
            this.removeUserInfo()
        }
    }
});

export function useUserStoreHook() {
    return useUserStore(store);
}
