import { t } from '../languages';
import { REGEXP_EMAIL, REGEXP_PWD } from "@/utils/regexp";

export default function useLoginHooks(fromData) {
    const userRules = {
        email: [
            {
                pattern: REGEXP_EMAIL,
                message: t("29"),
            },
            {
                required: true,
                message: t("23"),
            },
        ],
        verifyCode: [
            {
                required: true,
                message: t("30"),
            },
        ],
        password: [
            {
                required: true,
                message: t("31"),
            },
            {
                pattern: REGEXP_PWD,
                message: t("32"),
            },
        ],
        rePassword: [
            {
                required: true,
                message: t("26"),
            },
            {
                validator: () => {
                    return fromData.rePassword === fromData.password;
                },
                message: t("33"),
            },
        ],
    };


    return {
        userRules
    }
}