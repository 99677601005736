import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { t } from '../../languages';
import { ref } from "vue";
export default {
  __name: 'pagination',
  props: {
    //总数量
    total: {
      type: Number,
      default: 1
    },
    //当前页码
    pageNo: {
      type: Number,
      default: 1
    },
    //当前页数
    pageSize: {
      type: Number,
      default: 10
    },
    //选择分页条数
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 40]
    },
    //布局
    layout: {
      type: String,
      default: "sizes, prev, pager, next, jumper"
    },
    background: {
      type: Boolean,
      default: true
    }
  },
  emits: ["handleChange", "update:pageNo", "update:pageSize"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const handleSizeChange = val => {
      //修改pageNo的值
      emit("update:pageSize", val);
      //调用请求
      emit("handleChange");
    };
    const handleCurrentChange = val => {
      //修改pageNo的值
      emit("update:pageNo", val);
      //调用请求
      emit("handleChange");
    };
    return (_ctx, _cache) => {
      const _component_el_pagination = _resolveComponent("el-pagination");
      return _openBlock(), _createBlock(_component_el_pagination, {
        "current-page": props.pageNo,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => props.pageNo = $event),
        "page-size": props.pageSize,
        "onUpdate:pageSize": _cache[1] || (_cache[1] = $event => props.pageSize = $event),
        "page-sizes": props.pageSizes,
        background: props.background,
        layout: props.layout,
        total: props.total,
        onSizeChange: handleSizeChange,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "page-sizes", "background", "layout", "total"]);
    };
  }
};