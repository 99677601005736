import { t } from '../../languages';
import { defineStore } from "pinia";
import { store } from "@/store";
import { storageLocal } from "@pureadmin/utils";

const allCountryOption = {
    id: "",
    name: "全球",
    code: "",
};




const useCountryStore = defineStore({
    id: "country",
    state: () => ({
        countryInfo: storageLocal().getItem("countryInfo") || allCountryOption
    }),
    actions: {
        setCountryInfo(value) {
            this.countryInfo = value
			storageLocal().setItem("countryInfo", this.countryInfo);
        },
        getCountryInfo() {
            return this.countryInfo
        },
        getCountryId() {
            return this.countryInfo.id
        },
        getAllCountryOption() {
            return allCountryOption
        }
    }
});

export default function useCountryStoreHook() {
    return useCountryStore(store);
}