/* unplugin-vue-components disabled */import { ElConfigProvider as __unplugin_components_0 } from 'element-plus/es';import 'element-plus/es/components/config-provider/style/css';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { t } from './languages';
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import en from "element-plus/dist/locale/en.mjs";
import ru from "element-plus/dist/locale/ru.mjs";
import { injectVoerkaI18n } from "@voerkai18n/vue";
import { SystemLanguageEnum } from "@/common/constants";
export default {
  __name: 'App',
  setup(__props) {
    const i18n = injectVoerkaI18n();
    const activeLanguage = computed(() => {
      return i18n.activeLanguage;
    });
    const locale = computed(() => {
      switch (activeLanguage.value) {
        case SystemLanguageEnum.ZH_CN:
          return zhCn;
        case SystemLanguageEnum.EN_US:
          return en;
        case SystemLanguageEnum.RU_RU:
          return ru;
        default:
          return zhCn;
      }
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_config_provider = __unplugin_components_0;
      return _openBlock(), _createBlock(_component_el_config_provider, {
        locale: _unref(locale)
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};