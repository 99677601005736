import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-465c0a85"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "index-container"
};
const _hoisted_2 = {
  class: "index-header"
};
const _hoisted_3 = {
  class: "index-main"
};
const _hoisted_4 = {
  class: "index-footer"
};
import { t } from '../languages';
import commonHeader from "../components/common-header/common-header.vue";
import commonFooter from "../components/common-footer/common-footer.vue";
export default {
  __name: 'layout',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_routerView = _resolveComponent("routerView");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(commonHeader)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_routerView, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.fullPath
        })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.fullPath
        })) : _createCommentVNode("", true)]),
        _: 1
      })]), _createElementVNode("div", _hoisted_4, [_createVNode(commonFooter)])]);
    };
  }
};