import _defineProperty from "C:/wwwroot/www.qqlmt.com/mall-pc/node_modules/.pnpm/@babel+runtime@7.24.1/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import Axios from "axios";
import config from './config';
import { SystemLanguageEnum } from "@/common/constants";
import { i18nScope, t } from "@/languages";
import { useUserStoreHook } from "@/store/modules/user";
import { ElLoading } from 'element-plus';
import { ElMessage } from 'element-plus';
import router from '../router';

// axios默认配置
const defaultConfig = {
  baseURL: config.baseUrl,
  timeout: 5000
};
class PureHttp {
  constructor() {
    this.httpInterceptorsRequest();
    this.httpInterceptorsResponse();
  }

  // loading开始
  static startLoading() {
    PureHttp.loadingInstance = ElLoading.service({
      fullscreen: false,
      lock: true,
      text: t("43"),
      background: 'rgba(0, 0, 0, 0.4)'
    });
  }

  // loading结束
  static endLoading() {
    // setTimeout(function () {
    if (PureHttp.loadingInstance) {
      PureHttp.loadingInstance.close();
    }
    // }, 500)
  }
  // 打开loading
  static showFullScreenLoading() {
    if (PureHttp.needLoadingRequestCount === 0) {
      PureHttp.startLoading();
    }
    PureHttp.needLoadingRequestCount++;
  }
  // 关闭loading
  static tryHideFullScreenLoading() {
    if (PureHttp.needLoadingRequestCount <= 0) return;
    PureHttp.needLoadingRequestCount--;
    if (PureHttp.needLoadingRequestCount === 0) {
      PureHttp.endLoading();
    }
  }

  // 重连原始请求
  static retryOriginalRequest(config) {
    return new Promise(resolve => {
      PureHttp.requests.push(token => {
        config.headers["Authorization"] = token;
        resolve(config);
      });
    });
  }

  /** 请求拦截 */
  httpInterceptorsRequest() {
    PureHttp.axiosInstance.interceptors.request.use(async config => {
      // 配置多语言
      config.headers["lang"] = i18nScope.activeLanguage ?? SystemLanguageEnum.ZH_CN;

      // 需要token的接口
      const needList = ["/member/info", "/member/logout", "/file/uploadImage", "/member/updateInfo", "/member/updatePassword", "/platformFeedback/create", "/member/updateBindEmail", "/member/logout"];
      if (!needList.find(url => url === config.url)) {
        PureHttp.showFullScreenLoading();
        const tokenInfo = useUserStoreHook().getToken();
        if (tokenInfo && Object.keys(tokenInfo).length > 0) {
          config.headers["Authorization"] = tokenInfo.token;
        }
        return config;
      } else {
        return new Promise((resolve, reject) => {
          const tokenInfo = useUserStoreHook().getToken();
          if (tokenInfo && Object.keys(tokenInfo).length > 0) {
            const now = new Date().getTime();
            const expired = parseInt(tokenInfo.expires) - now <= 0;
            if (expired) {
              if (!PureHttp.isRefreshing) {
                PureHttp.isRefreshing = true;
                useUserStoreHook().handRefreshToken().then(res => {
                  const {
                    token
                  } = res;
                  PureHttp.requests.forEach(cb => cb(token));
                }).finally(() => {
                  PureHttp.requests = [];
                  PureHttp.isRefreshing = false;
                });
              }
              PureHttp.showFullScreenLoading();
              resolve(PureHttp.retryOriginalRequest(config));
            } else {
              config.headers["Authorization"] = tokenInfo.token;
              PureHttp.showFullScreenLoading();
              resolve(config);
            }
          } else {
            PureHttp.showFullScreenLoading();
            useUserStoreHook().cloaseLoginInfo();
            router.replace({
              path: "/account",
              query: {
                type: 1
              }
            });
            ElMessage.error(message);
            reject(data);
          }
        });
      }
    }, error => {
      PureHttp.tryHideFullScreenLoading();
      return Promise.reject(error);
    });
  }
  /** 响应拦截 */
  httpInterceptorsResponse() {
    const instance = PureHttp.axiosInstance;
    instance.interceptors.response.use(response => {
      PureHttp.tryHideFullScreenLoading();
      let {
        code,
        data,
        message
      } = response.data;
      if (code === 200) {
        return Promise.resolve(data);
      } else if (code === 1000 || code === 401) {
        if (PureHttp.showExpireMsg) {
          ElMessage.error(message);
          PureHttp.showExpireMsg = false;
          setTimeout(() => {
            PureHttp.showExpireMsg = true;
          }, 3000);
        }
        // 登陆失效
        useUserStoreHook().cloaseLoginInfo();
        router.replace({
          path: "/account",
          query: {
            type: 1
          }
        });
        return Promise.reject(data);
      } else {
        // 其他错误
        ElMessage.error(message);
        return Promise.reject(data);
      }
    }, error => {
      PureHttp.tryHideFullScreenLoading();
      return Promise.reject(error);
    });
  }
}
// token过期后，暂存待执行的请求
_defineProperty(PureHttp, "requests", []);
// 防止重复刷新token
_defineProperty(PureHttp, "isRefreshing", false);
// 初始化配置对象
_defineProperty(PureHttp, "initConfig", {});
// 保存当前Axios实例对象
_defineProperty(PureHttp, "axiosInstance", Axios.create(defaultConfig));
// 全局loading
_defineProperty(PureHttp, "loadingInstance", null);
// 记录请求次数
_defineProperty(PureHttp, "needLoadingRequestCount", 0);
_defineProperty(PureHttp, "showExpireMsg", true);
const http = new PureHttp();
export default PureHttp.axiosInstance;