/* unplugin-vue-components disabled */import { ElForm as __unplugin_components_3 } from 'element-plus/es';import 'element-plus/es/components/form/style/css';
import { ElButton as __unplugin_components_2 } from 'element-plus/es';import 'element-plus/es/components/button/style/css';
import { ElFormItem as __unplugin_components_1 } from 'element-plus/es';import 'element-plus/es/components/form-item/style/css';
import { ElInput as __unplugin_components_0 } from 'element-plus/es';import 'element-plus/es/components/input/style/css';
import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a7f03e6c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "login-container"
};
const _hoisted_2 = {
  class: "login-header"
};
const _hoisted_3 = {
  class: "login-title"
};
const _hoisted_4 = {
  class: "login-content"
};
const _hoisted_5 = {
  class: "login-footer"
};
import { t } from '../../../../languages';
import useLoginHooks from "@/hooks/useLogin";
import { useUserStoreHook } from "@/store/modules/user";
export default {
  __name: 'login',
  emits: ["setCurrentPage"],
  setup(__props, {
    emit: __emit
  }) {
    const router = useRouter();
    const loginRef = ref();
    const loginData = reactive({
      email: "",
      password: ""
    });
    const submitLoading = ref(false);
    const {
      userRules
    } = useLoginHooks(loginData);
    const loginRules = {
      ...userRules
    };
    const handleSubmit = async () => {
      try {
        await loginRef.value.validate();
        try {
          submitLoading.value = true;
          await useUserStoreHook().login({
            ...loginData
          });
          submitLoading.value = false;
          ElMessage({
            message: t("37"),
            type: "success",
            onClose() {
              router.push("/");
            }
          });
        } catch (error) {
          submitLoading.value = false;
        }
      } catch (error) {}
    };
    const emits = __emit;
    const toRegisterPage = () => {
      emits("setCurrentPage", 1);
    };
    const toForgetPasswordPage = () => {
      emits("setCurrentPage", 2);
    };
    return (_ctx, _cache) => {
      const _component_el_input = __unplugin_components_0;
      const _component_el_form_item = __unplugin_components_1;
      const _component_el_button = __unplugin_components_2;
      const _component_el_form = __unplugin_components_3;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)("21")) + " " + _toDisplayString(_unref(t)("35")), 1)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_form, {
        rules: loginRules,
        model: _unref(loginData),
        ref_key: "loginRef",
        ref: loginRef
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          class: "login-item",
          prop: "email"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            placeholder: _unref(t)('23'),
            clearable: "",
            modelValue: _unref(loginData).email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(loginData).email = $event)
          }, null, 8, ["placeholder", "modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          class: "login-item",
          prop: "password"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            placeholder: _unref(t)('25'),
            clearable: "",
            type: "password",
            modelValue: _unref(loginData).password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(loginData).password = $event)
          }, null, 8, ["placeholder", "modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          class: "login-item"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            class: "login-button",
            loading: _unref(submitLoading),
            onClick: handleSubmit
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)("2")), 1)]),
            _: 1
          }, 8, ["loading"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
        class: "login-register",
        onClick: toRegisterPage
      }, _toDisplayString(_unref(t)("3")), 1), _createElementVNode("div", {
        class: "login-forget",
        onClick: toForgetPasswordPage
      }, _toDisplayString(_unref(t)("36")), 1)])]);
    };
  }
};