import { t } from '../languages';
import { injectVoerkaI18n } from "@voerkai18n/vue";
import { currencySymbolEnum, currencyTypeEnum } from "@/common/constants";
export default function useFormatHooks() {
  const i18n = injectVoerkaI18n();
  const activeLanguage = computed(() => {
    return i18n.activeLanguage;
  });
  const formatCurrency = (currencyPriceList = []) => {
    let symbol = currencySymbolEnum[activeLanguage.value];
    let value = currencyPriceList.find(({
      currencyType
    }) => currencyType === currencyTypeEnum[activeLanguage.value])?.price;
    return `${symbol}${value}`;
  };
  const priceSymbol = computed(() => {
    let symbol = currencySymbolEnum[activeLanguage.value];
    return symbol;
  });
  return {
    formatCurrency,
    priceSymbol
  };
}