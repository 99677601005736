import { t } from './languages';
// 注册动画库
import 'animate.css';
// 滚动动画
import "wowjs/css/libs/animate.css";
// 注册轮播图
import { register } from 'swiper/element/bundle';
register();

import 'element-plus/theme-chalk/el-loading.css'

import Breadcrumb from "@/components/breadcrumb/breadcrumb.vue";
import Pagination from "@/components/pagination/pagination.vue";

import router from './router'
import { setupStore } from "@/store";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { createApp } from 'vue'
import App from './App.vue'

// 全局总线
import mitt from 'mitt'




//注册多语言
import i18nPlugin from '@voerkai18n/vue'
import { i18nScope } from '@/languages'
i18nScope.ready(() => {
    const app = createApp(App)

    // 注册全局组件
    app.component('Breadcrumb', Breadcrumb)
    app.component('Pagination', Pagination)

    // 注册全局mitt
    const bus = mitt()
    app.config.globalProperties.$bus = bus

    // 注册路由
    app.use(router)
    // 注册Vuex
    setupStore(app);
    // 注册ElementPlus图标
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
    app.use(i18nPlugin, {
        i18nScope
    })
    app.mount('#app')
})

