import { t } from './';
export default {
    "您好，欢迎来到跨国电商！": 1,
    "登录": 2,
    "立即注册": 3,
    "平台公告": 4,
    "搜索": 5,
    "企业": 6,
    "产品": 7,
    "获取验证码": 8,
    "秒": 9,
    "重新获取": 10,
    "隐私协议": 11,
    "跨国贸易介绍": 12,
    "平台介绍": 13,
    "总计": 14,
    "{0}条": 15,
    "企业推荐": 16,
    "产品推荐": 17,
    "平台资料": 18,
    "平台人员": 19,
    "企业新闻": 20,
    "您好，": 21,
    "欢迎来到忘记密码": 22,
    "请输入您的邮箱": 23,
    "请输入邮箱验证码": 24,
    "请输入您的密码": 25,
    "请输入确认密码": 26,
    "确认修改": 27,
    "验证码已发送": 28,
    "请输入正确格式的邮箱": 29,
    "请输入验证码": 30,
    "请输入密码": 31,
    "请输入正确密码格式": 32,
    "两次密码输入不一致": 33,
    "提交成功": 34,
    "欢迎你的登录": 35,
    "忘记密码": 36,
    "登录成功": 37,
    "欢迎你的注册": 38,
    "注册": 39,
    "已有账号？": 40,
    "立即登录": 41,
    "注册成功": 42,
    "加载中...": 43,
    "首页": 44,
    "企业列表": 45,
    "企业详情": 46,
    "产品列表": 47,
    "产品详情": 48,
    "用户设置": 49,
    "公告列表": 50,
    "公告详情": 51,
    "新闻详情": 52,
    "新闻列表": 53,
    "隐私协议列表": 54,
    "隐私协议详情": 55,
    "平台介绍列表": 56,
    "平台介绍详情": 57,
    "跨国贸易介绍列表": 58,
    "跨国贸易介绍详情": 59,
    "意见反馈": 60,
    "入驻企业": 61,
    "请先登录": 62,
    "当前位置": 63,
    "信息设置": 64,
    "我要入驻": 65,
    "退出登录": 66,
    "请输入关键字": 67,
    "请输入搜索内容": 68,
    "文件上传中": 69,
    "查看": 70,
    "移除": 71,
    "只能上传图片": 72,
    "图片大小不能超过{0}MB": 73,
    "最多上传{0}张图片，请先删除在上传": 74,
    "反馈内容": 75,
    "请输入反馈内容": 76,
    "反馈图片": 77,
    "提交反馈": 78,
    "已推荐": 79,
    "介绍": 80,
    "信息": 81,
    "分类": 82,
    "联系电话": 83,
    "电子邮件": 84,
    "所属地区": 85,
    "创建时间": 86,
    "企业行业": 87,
    "详细地址": 88,
    "企业简介": 89,
    "企业分类": 90,
    "暂无数据": 91,
    "提交信息": 92,
    "等待审核": 93,
    "审核结果": 94,
    "企业logo": 95,
    "企业名称": 96,
    "请输入您的企业名称": 97,
    "请输入您的联系电话": 98,
    "请输入您的电子邮件": 99,
    "请选择所属地区": 100,
    "请输入您的详细地址": 101,
    "所属行业": 102,
    "请选择所属行业": 103,
    "代理商": 104,
    "请选择代理商": 105,
    "请输入您的企业简介": 106,
    "企业轮播图": 107,
    "企业介绍图": 108,
    "审核中...": 109,
    "撤销审核，重新提交数据": 110,
    "入驻成功": 111,
    "如果想修改企业信息，请前往后台": 112,
    "入驻失败": 113,
    "重新提交企业信息": 114,
    "请上传企业logo": 115,
    "请输入企业名称": 116,
    "请输入联系电话": 117,
    "请输入电子邮件": 118,
    "请输入正确的电子邮件": 119,
    "请输入所属地区": 120,
    "请输入详细地址": 121,
    "请输入企业简介": 122,
    "请上传企业轮播图": 123,
    "请上传企业介绍图": 124,
    "查看更多": 125,
    "联系我们": 126,
    "请输入您的姓名或公司名称": 127,
    "请输入您的手机号": 128,
    "请输入您要留言的内容": 129,
    "提交留言": 130,
    "提交成功！": 131,
    "上一篇": 132,
    "下一篇": 133,
    "参考价格": 134,
    "商品": 135,
    "所属企业": 136,
    "商品分类": 137,
    "商品介绍": 138,
    "通知公告": 139,
    "点击上传": 140,
    "修改成功": 141,
    "个人信息": 142,
    "密码修改": 143,
    "邮箱绑定": 144,
    "邮箱": 145,
    "验证码": 146,
    "确认": 147,
    "密码": 148,
    "确认密码": 149,
    "昵称": 150,
    "请输入您的昵称": 151,
    "选择所属国家": 152,
    "{ value | date }": 153,
    "不限": 154,
    "平台行业": 155,
    "{ value | date('long') }": 156,
    "规格": 157,
    "生产地址": 158,
    "四川乐聚汇科技有限公司": 159
}