/* unplugin-vue-components disabled */import { ElButton as __unplugin_components_3 } from 'element-plus/es';import 'element-plus/es/components/button/style/css';
import { ElForm as __unplugin_components_2 } from 'element-plus/es';import 'element-plus/es/components/form/style/css';
import { ElFormItem as __unplugin_components_1 } from 'element-plus/es';import 'element-plus/es/components/form-item/style/css';
import { ElInput as __unplugin_components_0 } from 'element-plus/es';import 'element-plus/es/components/input/style/css';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6ba693b8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "userInfo-container"
};
const _hoisted_2 = {
  class: "userInfo-content"
};
const _hoisted_3 = {
  class: "userInfo-footer"
};
import { t } from '../../../../languages';
import { useUserStoreHook } from "@/store/modules/user";
import { updateUserInfo } from "@/http/api";
export default {
  __name: 'set-info',
  setup(__props) {
    const router = useRouter();
    const userInfo = computed(() => {
      return useUserStoreHook()?.userInfo;
    });
    const submitLoading = ref(false);
    const userInfoData = reactive({
      nickName: userInfo.value.nickName,
      avatar: userInfo.value.avatar
    });
    const userInfoRules = ref({
      nickName: [{
        required: true,
        message: "请输入昵称",
        trigger: "blur"
      }]
    });
    const userInfoRef = ref(null);
    const handleSubmit = async () => {
      try {
        await userInfoRef.value.validate();
        try {
          submitLoading.value = true;
          await updateUserInfo({
            ...userInfoData
          });
          submitLoading.value = false;
          ElMessage({
            message: t("141"),
            type: "success",
            onClose() {
              useUserStoreHook().setUserInfo();
              router.replace("/");
            }
          });
        } catch (error) {
          submitLoading.value = false;
        }
      } catch (error) {}
    };
    return (_ctx, _cache) => {
      const _component_el_input = __unplugin_components_0;
      const _component_el_form_item = __unplugin_components_1;
      const _component_el_form = __unplugin_components_2;
      const _component_el_button = __unplugin_components_3;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form, {
        class: "userInfo-form",
        "label-width": "auto",
        model: _unref(userInfoData),
        rules: _unref(userInfoRules),
        ref_key: "userInfoRef",
        ref: userInfoRef
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          prop: "nickName",
          label: _unref(t)('150')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            class: "userInfo-input",
            placeholder: _unref(t)('151'),
            clearable: "",
            modelValue: _unref(userInfoData).nickName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(userInfoData).nickName = $event)
          }, null, 8, ["placeholder", "modelValue"])]),
          _: 1
        }, 8, ["label"])]),
        _: 1
      }, 8, ["model", "rules"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
        class: "userInfo-update",
        onClick: handleSubmit,
        loading: _unref(submitLoading)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)("147")), 1)]),
        _: 1
      }, 8, ["loading"])])])]);
    };
  }
};