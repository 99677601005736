import { t } from './';
export default {
    "1": "您好，欢迎来到跨国电商！",
    "2": "登录",
    "3": "立即注册",
    "4": "平台公告",
    "5": "搜索",
    "6": "企业",
    "7": "产品",
    "8": "获取验证码",
    "9": "秒",
    "10": "重新获取",
    "11": "隐私协议",
    "12": "跨国贸易介绍",
    "13": "平台介绍",
    "14": "总计",
    "15": "{0}条",
    "16": "企业推荐",
    "17": "产品推荐",
    "18": "平台资料",
    "19": "平台人员",
    "20": "企业新闻",
    "21": "您好，",
    "22": "欢迎来到忘记密码",
    "23": "请输入您的邮箱",
    "24": "请输入邮箱验证码",
    "25": "请输入您的密码",
    "26": "请输入确认密码",
    "27": "确认修改",
    "28": "验证码已发送",
    "29": "请输入正确格式的邮箱",
    "30": "请输入验证码",
    "31": "请输入密码",
    "32": "请输入正确密码格式",
    "33": "两次密码输入不一致",
    "34": "提交成功",
    "35": "欢迎你的登录",
    "36": "忘记密码",
    "37": "登录成功",
    "38": "欢迎你的注册",
    "39": "注册",
    "40": "已有账号？",
    "41": "立即登录",
    "42": "注册成功",
    "43": "加载中...",
    "44": "首页",
    "45": "企业列表",
    "46": "企业详情",
    "47": "产品列表",
    "48": "产品详情",
    "49": "用户设置",
    "50": "公告列表",
    "51": "公告详情",
    "52": "新闻详情",
    "53": "新闻列表",
    "54": "隐私协议列表",
    "55": "隐私协议详情",
    "56": "平台介绍列表",
    "57": "平台介绍详情",
    "58": "跨国贸易介绍列表",
    "59": "跨国贸易介绍详情",
    "60": "意见反馈",
    "61": "入驻企业",
    "62": "请先登录",
    "63": "当前位置",
    "64": "信息设置",
    "65": "我要入驻",
    "66": "退出登录",
    "67": "请输入关键字",
    "68": "请输入搜索内容",
    "69": "文件上传中",
    "70": "查看",
    "71": "移除",
    "72": "只能上传图片",
    "73": "图片大小不能超过{0}MB",
    "74": "最多上传{0}张图片，请先删除在上传",
    "75": "反馈内容",
    "76": "请输入反馈内容",
    "77": "反馈图片",
    "78": "提交反馈",
    "79": "已推荐",
    "80": "介绍",
    "81": "信息",
    "82": "分类",
    "83": "联系电话",
    "84": "电子邮件",
    "85": "所属地区",
    "86": "创建时间",
    "87": "企业行业",
    "88": "详细地址",
    "89": "企业简介",
    "90": "企业分类",
    "91": "暂无数据",
    "92": "提交信息",
    "93": "等待审核",
    "94": "审核结果",
    "95": "企业logo",
    "96": "企业名称",
    "97": "请输入您的企业名称",
    "98": "请输入您的联系电话",
    "99": "请输入您的电子邮件",
    "100": "请选择所属地区",
    "101": "请输入您的详细地址",
    "102": "所属行业",
    "103": "请选择所属行业",
    "104": "代理商",
    "105": "请选择代理商",
    "106": "请输入您的企业简介",
    "107": "企业轮播图",
    "108": "企业介绍图",
    "109": "审核中...",
    "110": "撤销审核，重新提交数据",
    "111": "入驻成功",
    "112": "如果想修改企业信息，请前往后台",
    "113": "入驻失败",
    "114": "重新提交企业信息",
    "115": "请上传企业logo",
    "116": "请输入企业名称",
    "117": "请输入联系电话",
    "118": "请输入电子邮件",
    "119": "请输入正确的电子邮件",
    "120": "请输入所属地区",
    "121": "请输入详细地址",
    "122": "请输入企业简介",
    "123": "请上传企业轮播图",
    "124": "请上传企业介绍图",
    "125": "查看更多",
    "126": "联系我们",
    "127": "请输入您的姓名或公司名称",
    "128": "请输入您的手机号",
    "129": "请输入您要留言的内容",
    "130": "提交留言",
    "131": "提交成功！",
    "132": "上一篇",
    "133": "下一篇",
    "134": "参考价格",
    "135": "商品",
    "136": "所属企业",
    "137": "商品分类",
    "138": "商品介绍",
    "139": "通知公告",
    "140": "点击上传",
    "141": "修改成功",
    "142": "个人信息",
    "143": "密码修改",
    "144": "邮箱绑定",
    "145": "邮箱",
    "146": "验证码",
    "147": "确认",
    "148": "密码",
    "149": "确认密码",
    "150": "昵称",
    "151": "请输入您的昵称",
    "152": "选择所属国家",
    "153": "{ value | date }",
    "154": "不限",
    "155": "平台行业",
    "156": "{ value | date('long') }",
    "157": "规格",
    "158": "生产地址",
    "159": "四川乐聚汇科技有限公司"
}