import { t } from '../languages';
import { getEmailVerifyCode } from "@/http/api";
export default function useVarifyCodeHooks(verifyCodeRef, formRef, formData) {
  const verifyCodeText = ref("");
  const varifyCodeLoading = ref(false);
  const onVerifyCodeChange = text => {
    verifyCodeText.value = text;
  };
  const loadVerifyCode = async () => {
    if (verifyCodeRef.value.canGetCode) {
      const {
        email
      } = formData;
      formRef.value.validateField("email", valid => {
        if (valid) {
          verifyCodeText.value = "";
          varifyCodeLoading.value = true;
          getEmailVerifyCode({
            email
          }).then(() => {
            ElMessage.success(t("28"));
            varifyCodeLoading.value = false;
            verifyCodeRef.value.start();
          });
        }
      });
    }
  };
  return {
    onVerifyCodeChange,
    loadVerifyCode,
    verifyCodeText,
    varifyCodeLoading
  };
}