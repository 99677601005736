/* unplugin-vue-components disabled */import { ElForm as __unplugin_components_4 } from 'element-plus/es';import 'element-plus/es/components/form/style/css';
import { ElButton as __unplugin_components_3 } from 'element-plus/es';import 'element-plus/es/components/button/style/css';
import { ElFormItem as __unplugin_components_2 } from 'element-plus/es';import 'element-plus/es/components/form-item/style/css';
import { ElInput as __unplugin_components_1 } from 'element-plus/es';import 'element-plus/es/components/input/style/css';
import { ElIcon as __unplugin_components_0 } from 'element-plus/es';import 'element-plus/es/components/icon/style/css';
import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d632b46c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "forget-container"
};
const _hoisted_2 = {
  class: "forget-header"
};
const _hoisted_3 = {
  class: "forget-title"
};
const _hoisted_4 = {
  class: "forget-content"
};
const _hoisted_5 = {
  class: "forget-verifyText"
};
import { t } from '../../../../languages';
import VerifyCode from "@/components/verify-code/verify-code";
import useVarifyCodeHooks from "@/hooks/useVarifyCode";
import useLoginHooks from "@/hooks/useLogin";
import { forgetPassword } from "@/http/api";
export default {
  __name: 'forget-password',
  emits: ["setCurrentPage"],
  setup(__props, {
    emit: __emit
  }) {
    const router = useRouter();
    const submitLoading = ref(false);
    const forgetData = reactive({
      email: "",
      verifyCode: "",
      password: "",
      rePassword: ""
    });
    const verifyCodeRef = ref();
    const forgetRef = ref();
    const {
      verifyCodeText,
      varifyCodeLoading,
      onVerifyCodeChange,
      loadVerifyCode
    } = useVarifyCodeHooks(verifyCodeRef, forgetRef, forgetData);
    const {
      userRules
    } = useLoginHooks(forgetData);
    const forgetRules = {
      ...userRules
    };
    const handleSubmit = async () => {
      try {
        await forgetRef.value.validate();
        try {
          submitLoading.value = true;
          await forgetPassword({
            ...forgetData
          });
          submitLoading.value = false;
          ElMessage({
            message: t("34"),
            type: "success",
            onClose() {
              router.push("/login");
            }
          });
        } catch (error) {
          submitLoading.value = false;
        }
      } catch (error) {}
    };
    const emits = __emit;
    const toLoginPage = () => {
      emits("setCurrentPage", 0);
    };
    return (_ctx, _cache) => {
      const _component_Close = _resolveComponent("Close");
      const _component_el_icon = __unplugin_components_0;
      const _component_el_input = __unplugin_components_1;
      const _component_el_form_item = __unplugin_components_2;
      const _component_el_button = __unplugin_components_3;
      const _component_el_form = __unplugin_components_4;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)("21")) + " " + _toDisplayString(_unref(t)("22")), 1), _createElementVNode("div", {
        class: "forget-back",
        onClick: toLoginPage
      }, [_createVNode(_component_el_icon, {
        size: "25",
        color: "#666"
      }, {
        default: _withCtx(() => [_createVNode(_component_Close)]),
        _: 1
      })])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_form, {
        rules: forgetRules,
        model: _unref(forgetData),
        ref_key: "forgetRef",
        ref: forgetRef
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          class: "forget-item",
          prop: "email"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            placeholder: _unref(t)('23'),
            clearable: "",
            modelValue: _unref(forgetData).email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(forgetData).email = $event)
          }, null, 8, ["placeholder", "modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          class: "forget-item",
          prop: "verifyCode"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            placeholder: _unref(t)('24'),
            clearable: "",
            modelValue: _unref(forgetData).verifyCode,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(forgetData).verifyCode = $event)
          }, {
            append: _withCtx(() => [_createVNode(_unref(VerifyCode), {
              ref_key: "verifyCodeRef",
              ref: verifyCodeRef,
              onChange: _unref(onVerifyCodeChange)
            }, null, 8, ["onChange"]), _createVNode(_component_el_button, {
              loading: _unref(varifyCodeLoading),
              style: {
                "width": "200px"
              },
              onClick: _unref(loadVerifyCode)
            }, {
              default: _withCtx(() => [_createElementVNode("view", _hoisted_5, _toDisplayString(_unref(verifyCodeText)), 1)]),
              _: 1
            }, 8, ["loading", "onClick"])]),
            _: 1
          }, 8, ["placeholder", "modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          class: "forget-item",
          prop: "password"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            placeholder: _unref(t)('25'),
            clearable: "",
            type: "password",
            modelValue: _unref(forgetData).password,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(forgetData).password = $event)
          }, null, 8, ["placeholder", "modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          class: "forget-item",
          prop: "rePassword"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            placeholder: _unref(t)('26'),
            clearable: "",
            type: "password",
            modelValue: _unref(forgetData).rePassword,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(forgetData).rePassword = $event)
          }, null, 8, ["placeholder", "modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          class: "forget-item"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            class: "forget-button",
            loading: _unref(submitLoading),
            onClick: handleSubmit
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)("27")), 1)]),
            _: 1
          }, 8, ["loading"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"])])]);
    };
  }
};