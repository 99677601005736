import { t } from '../languages';
import { onMounted, onActivated } from "vue";
import { useRoute } from "vue-router";

// 路由更新
export default function useRouteHooks(refreshFn) {
    let active = false; // 防止多次触发
    // 是否为字符串
    const isString = (val) => typeof includeName === "string";

    onMounted(() => {
        active = true;
        refreshFn && refreshFn();
    });
    onActivated(() => {
        const route = useRoute();
        if (active) {
            active = false;
            return;
        }

        if (!refreshFn) return;

        let { fromName, includeName, excludeName } = route.meta;


        // 页面重新刷新
        if (!fromName) return refreshFn();

        // 包含的路由，包含就刷新
        if (includeName) {
            if (isString(includeName) && includeName === fromName) return refreshFn();
            if (includeName.includes(fromName)) return refreshFn();
        }

        // 排除的路由，不包含就刷新
        if (excludeName) {
            if (isString(excludeName) && excludeName !== fromName) return refreshFn();
            if (!excludeName.includes(fromName)) return refreshFn();
        }
    });
}

