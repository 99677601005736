import { t } from '../languages';
/**
 * 平台轮播图跳转类型
 */
export const PlatformCarouselLinkTypeEnum = {
    NONE: 0,
    COMPANY: 1,
    PRODUCT: 2,
    NOTICE: 3
}

// 首页推荐最大条数
export const MAX_RECOMMEND_COUNT = 8;