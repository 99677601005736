import { t } from '../languages';
import request from "./request.js";


// 返回首页轮播图
export const getHomeLoopImageListAll = (data) => request.post(`/homeLoopImage/listAll`, data)
// 返回企业推荐
export const getRecommendCompanyList = (data) => request.post(`/company/page`, { ...data, recommendStatus: 1 })
// 返回产品推荐
export const getRecommendProductList = (data) => request.post(`/product/page`, { ...data, recommendStatus: 1 })
// 返回行业列表
export const getIndustryListAll = (data) => request.post(`/industry/listAll`, data)
// 添加联系我们
export const createContactUs = (data) => request.post("/contactUs/create", data)
// 获取隐私协议列表
export const getAgreementList = (data) => request.post("/privacyAgreement/page", data)
// 获取隐私协议详情
export const getAgreementDetail = (data) => request.post("/privacyAgreement/info", data)
// 获取跨国贸易介绍列表
export const getTradeList = (data) => request.post("/countryTradeIntroduction/page", data)
// 获取跨国贸易介绍详情
export const getTradeDetail = (data) => request.post("/countryTradeIntroduction/info", data)
// 获取平台介绍列表
export const getIntroductionList = (data) => request.post("/platformIntroduction/page", data)
// 获取平台介绍详情
export const getIntroductionDetail = (data) => request.post("/platformIntroduction/info", data)
// 返回平台公告列表
export const getNoticeList = (data) => request.post("/platformNotice/page", data)
// 获取平台公告详情
export const getNoticeDetail = (data) => request.post("/platformNotice/info", data)
// 平台资料详情
export const getInformationDetail = (data) => request.post("/platformInformation/info", data)
// 平台人员列表
export const getDeveloperListAll = (data) => request.post("/platformDeveloper/listAll", data)
// 平台新闻列表
export const getNewsList = (data) => request.post("/platformNews/page", data)
// 平台新闻详情

export const getNewsDetail = (data) => request.post("/platformNews/info", data)
// 通过邮箱获取验证码
export const getEmailVerifyCode = (data) => request.post("/member/getAuthCode", data)
// 注册用户
export const registerUser = (data) => request.post("/member/register", data)
// 用户邮箱登录
export const emailLogin = (data) => request.post("/member/emailLogin", data)
// 忘记密码
export const forgetPassword = (data) => request.post("/member/forgotPassword", data)
// 根据 refreshToken 刷新token
export const refreshToken = (data, config) => request.post("/member/refreshToken", data, {
    ...config
})
// 修改密码
export const updatePassword = (data) => request.post("/member/updatePassword", data)
// 获取用户信息
export const getUserInfo = (data) => request.post("/member/info", data)



// 修改用户信息
export const updateUserInfo = (data) => request.post("/member/updateInfo", data)
// 退出登录
export const logout = (data) => request.post("/member/logout", data)
// 返回企业列表
export const getCompanyList = (data) => request.post(`/company/page`, data)
// 返回企业详情
export const getCompanyDetail = (data) => request.post(`/company/info`, data)
// 返回产品列表
export const getProductList = (data) => request.post(`/product/page`, data)
// 返回产品详情
export const getProductDetail = (data) => request.post(`/product/info`, data)
// 返回商品分类
export const getProductCategoryListAll = (data) => request.post(`/productCategory/listAll`, data)
// 新增反馈
export const createFeedback = (data) => request.post("/platformFeedback/create", data)
// 文件上传
export const uploadImage = (data) => request.post(`/file/uploadImage`, data, {
    headers: {
        "Content-Type": "multipart/form-data"
    }
})
// 更新绑定邮箱
export const updateEmail = (data) => request.post("/member/updateBindEmail", data)
// 通过companyId返回商品分类列表
export const getProductCategoryListByCompanyId = (data) => request.post(`/productCategory/listByCompanyId`, data)
// 返回代理人员列表
export const getAgentList = (data) => request.post(`/company/agent/list`, data)
// 用户申请企业入驻
export const applyCompany = (data) => request.post("/company/apply", data)
// 返回地区列表
export const getAreaListAll = (data) => request.post("/area/listAll", data)
// 查询当前登录用户企，入驻企业审核状态
export const getCurrentCompanyVerifyStatus = (data) => request.post("/company/getCurrentCompanyVerifyStatus", data)
// 查询当前登录用户企信息
export const getCurrentCompanyDetail = (data) => request.post("/company/getCurrentUnverifiedCompany", data)
// 获取平台背景图详情
export const getPlatformBackground = (data) => request.post("/platformBackground/info", data)
// 仅查询一级地区列表信息
export const getCountryList = (data) => request.post("/area/listOnlyOneLevel", data)