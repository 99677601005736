import { t } from '../languages';
// 邮箱正则
const REGEXP_EMAIL = /^\w+(-+.\w+)*@\w+(-.\w+)*.\w+(-.\w+)*$/;
// 密码正则（密码格式应为8-18位数字、字母、符号的任意两种组合）
const REGEXP_PWD =
  /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[()])+$)(?!^.*[\u4E00-\u9FA5].*$)([^(0-9a-zA-Z)]|[()]|[a-z]|[A-Z]|[0-9]){8,18}$/;

export {
  REGEXP_PWD,
  REGEXP_EMAIL
}