import { t } from '../../languages';
import { computed } from "vue";




/**
 * 多语言配置
 */

const SystemLanguageEnum = {
  ZH_CN: "zh",
  EN_US: "en",
  RU_RU: "ru"
}


/**
 * 货币符号配置
 */
const currencySymbolEnum = {
  "zh": "¥",
  "en": "$",
  "ru": "₽",
}

/**
 * 货币枚举
 */
const currencyTypeEnum = {
  "zh": 0,
  "ru": 1,
  "en": 2,
}


/**
 * 平台轮播图跳转类型
 */
const PlatformCarouselLinkTypeEnum = {
  NONE: 0,
  COMPANY: 1,
  PRODUCT: 2,
  NOTICE: 3
}

// 首页推荐最大条数
const MAX_RECOMMEND_COUNT = 8;


/**
 * 企业审核状态
 */
const companyVerifyStatusEnum = {
  NONE: -1,
  PROGRESS: 0,
  PASS: 1,
  REJECT: 2
}

export {
  SystemLanguageEnum,
  currencySymbolEnum,
  currencyTypeEnum,
  PlatformCarouselLinkTypeEnum,
  MAX_RECOMMEND_COUNT,
  companyVerifyStatusEnum
}



